import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import { withCoreComponent, withLocale } from 'core/hocs';
import { Banners as BannersCore } from 'core/components';
import { wrapRoute2Locale } from 'core/helpers';

import { Carousel } from 'components/carousel/carousel';
import { IconArrowRight } from 'components/icons/icon-arrow-right/icon-arrow-right';
import { GA } from 'helpers/ga';
import { BannersCarouselPreloader } from './banners-carousel-preloader';
import { withPixelRatio } from '../../hocs/with-pixel-ratio';

import './banners-carousel.scss';

const INTERNAL_LINKS = [
  'sport',
  'league',
  'live',
  'event',
  'search',
  'watchlist',
  'my-bets',
  'sports',
  'bets-feed',
];

const settings = {
  dots: true,
  infinite: true,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 500,
  useTransform: false,
  lazyLoad: 'ondemand',
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 2103,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

class BannersCarouselUI extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape()),
    isInProgress: PropTypes.bool.isRequired,
    pixelRatio: PropTypes.string.isRequired,
    parent: PropTypes.string,
    history: PropTypes.shape().isRequired,
    locale: PropTypes.string.isRequired,
  };

  static defaultProps = {
    items: null,
    parent: null,
  };

  state = {
    isMounted: false,
  };

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !R.equals(this.props, nextProps) || !R.equals(this.state, nextState);
  }

  getMobileImageUrl = ({ url1x, url2x, url3x }) => {
    const { pixelRatio } = this.props;

    if (pixelRatio === '@2x') {
      return url2x;
    }

    if (pixelRatio === '@3x') {
      return url3x;
    }

    return url1x;
  };

  getDesktopImageUrl = ({ url1x, url2x }) => {
    const { pixelRatio } = this.props;

    if (pixelRatio === '@2x' || pixelRatio === '@3x') {
      return url2x;
    }

    return url1x;
  };

  onClick = (e) => {
    e.stopPropagation();
    const { dataset: { link } } = e.currentTarget;
    const { parent, locale, history } = this.props;

    GA.event({
      category: 'navigation',
      label: 'banners-click',
    });

    if (!parent || !link || link === '/') {
      return;
    }

    const regExp = new RegExp('^(?:[a-z]+:)?//', 'i');

    if (regExp.test(link)) {
      window.open(link, '_blank', 'noopener, noreferrer');

      return;
    }

    const preparedLink = link.startsWith('/') ? link : `/${link}`;
    const isInternalLink = R.compose(
      Boolean,
      R.length,
      R.filter(R.includes(R.__, preparedLink))
    )(INTERNAL_LINKS);

    if (isInternalLink) {
      history.push(wrapRoute2Locale(preparedLink, locale));
    } else if (window && window.parent && window.parent.postMessage) {
      window.parent.postMessage({ type: 'SPORTSBOOK_CLICK_BANNER', data: { link: preparedLink } }, '*');
    }
  };

  render() {
    const { items, isInProgress } = this.props;
    const { isMounted } = this.state;

    return (
      <div className={classNames('banners-carousel mt-sm-2 mb-2_5', { 'is-one-item': items && items.length && items.length === 1 })}>
        {isMounted && !isInProgress && items
          ? (
            <Carousel
              settings={settings}
              iconRight={<IconArrowRight />}
              iconLeft={<IconArrowRight />}
            >
              {items.map(({
                id,
                title,
                cta,
                link,
                mobileImage,
                desktopImage,
                align,
              }) => (
                <div
                  role="button"
                  tabIndex="0"
                  key={id}
                  className="banners-carousel-item pl-2 pl-sm-4 pt-3_5 position-relative"
                  draggable={false}
                  onClick={this.onClick}
                  onKeyPress={this.onClick}
                  data-link={link}
                >
                  {!!title && (
                    <div className="banners-carousel-item-title mt-sm-4 h3 font-weight-bolder overflow-hidden">
                      {title}
                    </div>
                  )}

                  {!!cta && (
                    <Button
                      size="sm"
                      color="primary"
                      className="mt-3 mt-sm-7"
                      onClick={this.onClick}
                      data-link={link}
                    >
                      {cta}
                    </Button>
                  )}

                  <div
                    style={{
                      backgroundImage: `url("${this.getMobileImageUrl(mobileImage)}")`,
                      backgroundPositionX: align,
                    }}
                    className="banners-carousel-bg-mobile d-sm-none position-absolute"
                  />
                  <div
                    style={{
                      backgroundImage: `url("${this.getDesktopImageUrl(desktopImage)}")`,
                      backgroundPositionX: align,
                    }}
                    className="banners-carousel-bg-desktop d-none d-sm-block position-absolute"
                  />
                </div>
              ))}
            </Carousel>
          )
          : <BannersCarouselPreloader />}
      </div>
    );
  }
}

export const BannersCarousel = withRouter(
  withLocale(
    withPixelRatio(
      withCoreComponent(BannersCore, BannersCarouselUI)
    )
  )
);
