import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withLineInfo } from 'core/hocs';
import { TOP_EVENTS_TYPES, UPCOMING_GAMES_TIME_FILTERS } from 'core/constants';

import { BannersCarousel } from 'components/banners-carousel/banners-carousel';
import { TopEvents } from 'components/top-events/top-events';
import { InplayGames } from 'components/inplay-games/inplay-games';
import { UpcomingGames } from 'components/upcoming-games/upcoming-games';
import { NoBetsStub } from 'components/no-bets-stub/no-bets-stub';
import { PAGE_NAMES } from '../../constants';

export const HomeUI = ({
  lineInfo,
  isLineInfoInProgress,
  timeFilter,
}) => {
  const areNoBets = !isLineInfoInProgress && lineInfo && !lineInfo.eventsAmount
  && timeFilter === UPCOMING_GAMES_TIME_FILTERS.ALL_TIME;

  return (
    <div>
      <BannersCarousel />
      <NoBetsStub isShowed={areNoBets} isButtonHide />
      <div className={classNames({ 'd-none': areNoBets })}>
        <TopEvents coreProps={{ type: TOP_EVENTS_TYPES.ALL }} />
        <InplayGames pageName={PAGE_NAMES.HOME} className="mb-2_5" />
        <UpcomingGames pageName={PAGE_NAMES.HOME} />
      </div>
    </div>
  );
};

HomeUI.propTypes = {
  lineInfo: PropTypes.shape(),
  isLineInfoInProgress: PropTypes.bool.isRequired,
  timeFilter: PropTypes.number.isRequired,
};

HomeUI.defaultProps = {
  lineInfo: null,
};

export default withLineInfo(HomeUI);
